<template lang="html">
  <div class="row">
    <div class="card mx-auto">
      <div class="card-header ">
        <h4>Registruj Zaposlenog:</h4>
      </div>
      <div class="validation">
        {{ validation }}
      </div>
      <div class="card-body">
        <form @submit.prevent="registerUser">
          <div class="form-group">
            <label for="username">Username</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="username"
              v-model="username"
              id="username"
            />
          </div>
          <div class="form-group">
            <label for="Name">Ime i prezime</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="name"
              v-model="name"
              id="name"
            />
          </div>
          <div class="form-group">
            <label for="Email">Email</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="email"
              v-model="email"
              id="email"
            />
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input
              class="form-control"
              type="password"
              placeholder=""
              name="password"
              v-model="password"
              id="password"
            />
          </div>
          <div class="form-group">
            <label for="confirm_password">Potvrdi password</label>
            <input
              class="form-control"
              type="password"
              placeholder=""
              name="confirm_password"
              v-model="confirm_password"
              id="confirm_password"
            />
          </div>

          <div class="group">
            <label for="companys">Kompanija: </label>
            <select
              name="type"
              id="type"
              v-model="type"
              @change="onChangeCompany($event)"
            >
              <option v-for="company in companys" v-bind:key="company.name">{{
                company.name
              }}</option>
            </select>
          </div>
          <div class="group">
            <label for="companys">Sektor: </label>
            <select
              name="type"
              id="type"
              v-model="sector"
              @change="onChangeSector($event)"
            >
              <option v-for="sek in sektori" v-bind:key="sek">{{ sek }}</option>
            </select>
          </div>
          <div class="group">
            <label for="companys">Radno Mesto:</label>
            <select name="type" id="type" v-model="radnoMesto">
              <option v-for="rm in radnaMesta" v-bind:key="rm">{{ rm }}</option>
            </select>
          </div>
          <button class="btn btn-primary">Registruj</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      username: "",
      password: "",
      confirm_password: "",
      name: "",
      email: "",
      type: "",
      radnoMesto: "",
      sector: "",
      sektori: [],
      radnaMesta: [],
      validation: "",
      tempCompany: "",
      //createdUserId: "",
      //companyKpiConnector: 0,
    };
  },
  computed: mapGetters(["companys", "users", "usersSameCompany"]),
  methods: {
    ...mapActions([
      "register",
      "getCompanies",
      "getAllUsers",
      "getCollegues",
      "addPerformanceEval",
    ]),
    async registerUser() {
      // eslint-disable-next-line no-unused-vars
      this.validation = "";
      let user = {
        username: this.username.trim(),
        password: this.password,
        confirm_password: this.confirm_password,
        email: this.email,
        name: this.name.trim(),
        type: this.type,
        radnoMesto: this.radnoMesto,
        sektor: this.sector,
      };
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].username == user.username) {
          this.validation =
            "Username je već registrovan. Izaberite drugačiji username.";
        }
      }
      if (this.validation == "") {
        for (i = 0; i < this.users.length; i++) {
          if (this.users[i].email == user.email) {
            this.validation =
              "Email je već registrovan. Izaberite drugačiji Email.";
          }
        }
      }
      if (this.validation == "") {
        if (user.username == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.password == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.confirm_password == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.email == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.name == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.type == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.radnoMesto == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
        if (user.sektor == "") {
          this.validation = "Popunite sva polja prilikom registracije.";
        }
      }
      if (this.validation == "") {
        this.register(user).then((res) => {
          if (res.data.success) {
            // this.$router.push("login");
            this.validation = `Uspešno registrovan zaposleni ${user.name}`;
          }
        });
      }
      // await this.getCompanies();
      // await this.getCollegues(this.tempCompany.name);
      // await this.getUserId();
      // await this.addPerformanceEvaluationOnUserCreate();
    },

    // async getUserId() {
    //  for(let i = 0; i < this.usersSameCompany.length; i++) {
    //       if(this.username == this.usersSameCompany[i].username){
    //          this.createdUserId = this.usersSameCompany[i]._id
    //      }
    //     }
    // },

    // async addPerformanceEvaluationOnUserCreate(){
    //  let kpiData={
    //        userId:this.createdUserId,
    //        companyKpiConnector:this.companyKpiConnector
    //      }
    //    await this.addPerformanceEval(kpiData);
    // },

    onChangeCompany(event) {
      this.tempCompany = "";
      this.sektori = [];
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == event.target.value) {
          this.tempCompany = this.companys[i];
          //  this.companyKpiConnector=this.companys[i].performanceEvaluation.length-1;
        }
      }
      for (i = 0; i < this.tempCompany.sektori.length; i++) {
        this.sektori.push(this.tempCompany.sektori[i].name);
      }
    },
    onChangeSector(event) {
      this.radnaMesta = [];

      let tempCompany;
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == this.type) {
          tempCompany = this.companys[i];
        }
      }

      let tempSector;
      for (i = 0; i < tempCompany.sektori.length; i++) {
        if (tempCompany.sektori[i].name == event.target.value) {
          tempSector = tempCompany.sektori[i];
        }
      }
      for (i = 0; i < tempSector.radnaMesta.length; i++) {
        this.radnaMesta.push(tempSector.radnaMesta[i]);
      }
    },
    sendMessage() {
      // Check if the socket is initialized
      if (this.socket) {
        // Send the message to the server
        this.socket.emit("sendMessage", true);
        console.log("Message sent");
      } else {
        console.error("WebSocket connection not initialized");
      }
    },
  },
  created() {
    this.getCompanies();
    this.getAllUsers();
  },
};
</script>

<style lang="css" scoped>
.row {
  padding-top: 5%;
  text-align: center;
  width: 100%;
}
.card {
  width: 60%;
  border-radius: 0;
}
.btn {
  width: 60%;
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
.form-group {
  padding-top: 10px;
}
.form-control {
  border-radius: 0;
}
.card-header {
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
